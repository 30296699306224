<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Benefits Data Services - on demand
      </h2>
      <img
        src="../../assets/img/tools_Benefit_Data_Services.jpg"
        alt="Expatriate Practice Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="py-4">
          While too little benefits and allowances can pose a problem to attracting talents,
          an overly generous benefits and allowance policy can be costly.
        </p>
        <br>
        <p class="py-4">
            To assist companies in reviewing their policies to ensure benefits
            costs are kept within manageable
            limits while maintaining market competitiveness,
            MRC offers Employee Benefits Services (on demand)
            to provide information on market practices in a quantitative manner.
        </p>
        <br>
        <p class="py-4">
        We provide benchmarking capabilities for the following 15 benefit groups:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Car / Allowance / Mileage</li>
          <li>Transportation</li>
          <li>Housing</li>
          <li>Medical</li>
          <li>Paid Leave</li>
          <li>Insurance</li>
          <li>Air Travel Policy</li>
          <li>Club Membership</li>
          <li>Employee's Education</li>
          <li>Children's Education</li>
          <li>Overtime Compensation</li>
          <li>Long Service Award</li>
          <li>Pension</li>
          <li>Severance Payment</li>
          <li>Flexible Benefits</li>
        </ul>
        <p class="py-4">
            Data will be presented by hierarchy,
            i.e. Company Head, Function Head, Managers, Professionals, General Employees
            or Production Workers. Customised Reports for Peers & Cities will be available.
        </p>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
